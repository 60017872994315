import React from 'react'
import { Link, /* withPrefix */ } from 'gatsby'
import styled from 'styled-components'
import LogoImage from '../LogoImage'
import Slider from '../Slider'
import Icon from '../Icon'
import Socials from '../Socials' 
import TopRecipes from '../TopRecipes'
import TopPlaces from '../TopPlaces'
import SearchBar from '../SearchBar'
import BurgerMenu from '../BurgerMenu'
import AnalyticsConsent from '../AnalyticsConsent'
import { Helmet } from 'react-helmet'
import pizzaIcon from '../../../static/pizza-icon.png'
import gentlemanIcon from '../../../static/gentleman-icon.png'
import colosseumIcon from '../../../static/colosseum-icon.png'
import flightIcon from '../../../static/flight-icon.png'
import  theaterIcon from '../../../static/theater-icon.png'

export const PageLayout = ({ children }) => {
  
  return (
    <MainWrapper>
      <AnalyticsConsent />
      <Helmet>
                 
      </Helmet> 
      
      <HeaderWrapper>
        <LogoImage />
      </HeaderWrapper>

      <SocialsWrapper>
        <Socials />
      </SocialsWrapper>

      <SearchBarWrapper>
        <SearchBar />
      </SearchBarWrapper>

      <TopRecipesWrapper>
        <TopRecipes />
      </TopRecipesWrapper>

      <TopPlacesWrapper>
        <TopPlaces />
      </TopPlacesWrapper>

      <SliderWrapper>
        <Slider />
      </SliderWrapper>

      <LeftFlag />
      <RightFlag />

      <BurgerMenuWrapper>
        <BurgerMenu />
      </BurgerMenuWrapper>

      <MenuWrapper>
        <Menu />
      </MenuWrapper>

      <SectionWrapper>
       {children}
      </SectionWrapper>

      <PaginationWrapper>
        <Link to='/articles/2'>
          &gt;&gt; More Articles Here &lt;&lt;
        </Link>
      </PaginationWrapper>
      
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(10px, 1fr)) repeat(8, minmax(100px, 1fr)) repeat(4, minmax(10px, 1fr));
  grid-template-rows: minmax(20vh, 200px) minmax(50px, 60px) repeat(2, minmax(100px, 10vh)) auto auto; 
  height: 100vh;

  @media only screen and (max-width: 1120px) {
    grid-template-rows: 50px minmax(70px, 15vh) minmax(50px, 60px) repeat(2, minmax(100px, 10vh)) auto auto; 
    grid-template-columns: repeat(16, minmax(10px, 1fr));
    
  }

  @media only screen and (max-width: 680px) {
    grid-template-rows: 50px minmax(70px, 15vh) repeat(2, minmax(100px, 10vh)) auto auto; 
  }

  @media only screen and (max-width: 500px) {
    grid-template-rows: 50px minmax(70px, 15vh) repeat(2, minmax(100px, 10vh)) auto auto; 

  }
`

const HeaderWrapper = styled.div`
  grid-column: 5 / 13;
  grid-row: 1 / 2;

  @media only screen and (max-width: 1120px) { 
    grid-row: 2 / 3;
    grid-column: 4 / 14;
  }

  @media only screen and (max-width: 680px) {
    grid-column: 1 / end;
  }

  @media only screen and (max-width: 500px) { 
    grid-column: 2 / 16;
  }
`

const SocialsWrapper = styled.div`
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  z-index: 1;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1120px) { 
    grid-row: 1 / 2;
    grid-column: 1 / 9;
    flex-direction: row;
    background-color: ${props => props.theme.green};  
    background: -webkit-linear-gradient(to right, ${props => props.theme.green}, white); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, ${props => props.theme.green}, white); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  @media only screen and (max-width: 680px) {
    grid-column: 1 / end;
    background: ${props => props.theme.green};  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, ${props => props.theme.green}, white, white, ${props => props.theme.red});  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, ${props => props.theme.green}, white, white, ${props => props.theme.red}); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
`

const SearchBarWrapper = styled.div`
  grid-column: 13 / end;
  grid-row: 1 / 2;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    padding: .4rem; 
  }

  @media only screen and (max-width: 1120px) { 
    grid-row: 1 / 2;
    grid-column: 9 / end;
    flex-direction: row;
    background-color: ${props => props.theme.red};
    background: -webkit-linear-gradient(to right, white, ${props => props.theme.red}); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, white, ${props => props.theme.red}); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  @media only screen and (max-width: 680px) {
    display: none;
  }
`

const TopRecipesWrapper = styled.div`
  grid-column: 1 / 5;
  grid-row: 3 / end;
  z-index: 1;
  padding: 2rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
`

const TopPlacesWrapper = styled.div`
  grid-column: 13 / end;
  grid-row: 3 / end;
  z-index: 1;  

  padding: 2rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
`

const SliderWrapper = styled.div`
  grid-column: 5 / 13;
  grid-row: 3 / 5;
  height: 100%;

  @media only screen and (max-width: 1120px) { 
    display: none;
  }
`

const SectionWrapper = styled.div`
  z-index: 600;
  grid-column: 5 / 13;
  grid-row: 5 / 7;
  height: auto;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: ${props => props.theme.red} white;
  -webkit-scrollbar {
    width: 12px;
  }
  -webkit-scrollbar-track {
    background: white;
  }
  -webkit-scrollbar-thumb {
    background-color: ${props => props.theme.red};
    border-radius: 20px;
    border: 3px solid white;
  }

  @media only screen and (max-width: 1120px) { 
    grid-row: 4 / 9;
    grid-column: 2 / 16;
  }

  @media only screen and (max-width: 800px) { 
    grid-column: 1 / end;
  }

  @media only screen and (max-width: 680px) {
    grid-row: 3 / 9;
  }
`

const PaginationWrapper = styled.div`
  grid-column: 5 / 13;
  grid-row: 7 / 8;
  font-size: 1.2rem;
  font-family: 'Bangers','cursive';
  text-align: center;
  padding: 1rem;
  white-space: nowrap;

  a {
    text-decoration: none;
    color: black;
    background: ${props => props.theme.transparentGreen};  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, ${props => props.theme.transparentGreen}, r${props => props.theme.transparentWhite}, ${props => props.theme.transparentRed});  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, ${props => props.theme.transparentGreen}, ${props => props.theme.transparentWhite}, ${props => props.theme.transparentRed}); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: .3rem;
    border-radius: .5rem;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -webkit-box-shadow: 0 8px 6px -6px black;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0 8px 6px -6px black;  /* Firefox 3.5 - 3.6 */
    box-shadow:         0 8px 6px -6px black;

    &:hover {
      background: ${props => props.theme.green};  /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, ${props => props.theme.green}, r${props => props.theme.transparentWhite}, ${props => props.theme.red});  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, ${props => props.theme.green}, ${props => props.theme.transparentWhite}, ${props => props.theme.red}); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      
    }

    &:focus, &:active {
      -webkit-box-shadow: 0 0 0 0 black;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      -moz-box-shadow:    0 0 0 0 black;  /* Firefox 3.5 - 3.6 */
      box-shadow:         0 0 0 0 black;
    }
  }

  @media only screen and (max-width: 1120px) { 
    grid-row: 9 / 10;
  }

  @media only screen and (max-width: 680px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 300px) {
    font-size: .7rem;
  }

 
`

const LeftFlag = styled.div`
  grid-column: 1 / 5;
  grid-row: 1 / end;
  
  background-color: ${props => props.theme.green};
  /* background: rgb(2,0,36);
  background: linear-gradient(270deg, rgba(2,0,36,1) 0%, rgba(15,148,73,1) 58%, rgba(0,255,156,1) 100%); 
  clip-path: polygon(100% 0, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 100% 100%, 0 100%, 0% 70%, 0% 35%, 0 0); */
  
  background: -webkit-linear-gradient(to right, ${props => props.theme.green}, white); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, ${props => props.theme.green}, white); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  @media only screen and (max-width: 1120px) { 
    display: none;
  }
`

const RightFlag = styled.div`
  grid-column: 13 / end;
  grid-row: 1 / end;
  
  background-color: ${props => props.theme.red};
  /* background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(193,28,28,1) 58%, rgba(255,0,69,1) 100%);
  clip-path: polygon(0 0, 100% 0, 100% 35%, 100% 70%, 100% 100%, 0 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%); */

  background: -webkit-linear-gradient(to right, white, ${props => props.theme.red}); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, white, ${props => props.theme.red}); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  @media only screen and (max-width: 1120px) { 
    display: none;
  }
`

const BurgerMenuWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 680px) {
    display: block;

    
  }
`

const MenuWrapper = styled.div`
  grid-column: 1 / end;
  grid-row: 2 / 3;
  z-index: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;

    
    @media only screen and (max-width: 1120px) {
      border-right: 1px solid ${props => props.theme.red};
    }
    
  }

  @media only screen and (max-width: 1120px) { 
    grid-row: 3 / 4;
    grid-column: 1 / end;
    width: 100%;

    a:first-child {
      border-left: 1px solid ${props => props.theme.red};
    }
  }

  @media only screen and (max-width: 680px) {
    display: none;
  }
`
const Menu = () => (
  <>
    <StyledMenuLink to='/'>
      <MenuItem>Italy Presents Italy</MenuItem>
      <Icon src={colosseumIcon} description={"Colesseum in Rome"}/>
    </StyledMenuLink>
    <StyledMenuLink to='/italian-curiosities/'>
      <MenuItem>Italian Curiosities</MenuItem>
      <Icon src={theaterIcon} description={"Talking faces"} />
    </StyledMenuLink>
    <StyledMenuLink to='/italian-cuisine/'>
      <MenuItem>Italian Cuisine</MenuItem>
      <Icon src={pizzaIcon} description={"Pizza"}/>
    </StyledMenuLink>
    <StyledMenuLink to='/italian-heritage/'>
      <MenuItem>Italian Heritage</MenuItem>
      <Icon src={gentlemanIcon} description={"Gentleman wearing typical Borsalino Hat"}/>
    </StyledMenuLink>
    <StyledMenuLink to='/travel-to-italy/'>
      <MenuItem>Travel To Italy</MenuItem>
      <Icon src={flightIcon} description={"Flight"}/>
    </StyledMenuLink>
  </>
)

const StyledMenuLink = styled(Link)`

  img {
    @media only screen and (max-width: 1120px) {
      display: none;
    }
  }

  &:hover {
    div {
      border: 2px solid ${props => props.theme.green};
      color: ${props => props.theme.red};
      font-size: 1.2rem;

      @media only screen and (max-width: 1120px) {
        font-size: 1rem;
        border: 0;
        border-top: 1px solid ${props => props.theme.green};
        border-bottom: 1px solid ${props => props.theme.green};
      }
    }

    img {
      transform: scale(1.7);
      border: 2px solid ${props => props.theme.red};
    }
  }
`

const MenuItem = styled.div`
  margin-right: 1rem;
  margin-left: 1rem;
  font-family: 'Inconsolata', 'monospace';
  font-size: 1rem;
  padding: .6rem;
  border: 1px solid transparent;
  border-radius: .9rem;
  white-space:nowrap;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  
  @media only screen and (max-width: 1120px) {
    font-size: .8rem;
    margin-right: 0;
    margin-left: 0;
    
    border-radius: 0;
  }
`

