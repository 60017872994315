import { graphql, Link } from 'gatsby'
import React from 'react'
import SEO from 'react-seo-component'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { PageLayout } from '../components/Layouts/PageLayout'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

export default ({ data }) => {
  const {
    description,
    title,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata()

  return (
    <PageLayout>
      <SEO
        title={'home'}
        titleTemplate={title}
        description={description || 'nothing'}
        image={`${siteUrl}${image}`}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />

      <IndexWrapper>
        {data.allPublishedPostsByDate.nodes.map(
          ({ id, frontmatter, fields }) => (
            <PostWrapper key={id}>
              <Link to={fields.slug}>
                
                <Date>{frontmatter.date}</Date>
                 
                <ArticleWrapper>
                  <Cover>
                    {frontmatter.cover ? 
                      (
                        <Image
                          sizes={frontmatter.cover.childImageSharp.sizes}
                          alt={frontmatter.coverName}
                        />
                      ) : null
                    } 
                  </Cover> 
                  <Content>
                    <Category>{frontmatter.category}</Category>
                    <Title>{frontmatter.title}</Title>
                  </Content>
                </ArticleWrapper>
              </Link>
            </PostWrapper>
          )
        )}
      </IndexWrapper>
    </ PageLayout>
  )
}

const Date = styled.div`
  margin: .1rem 1rem;
  color: black;
  font-size: .8rem;

  @media only screen and (max-width: 800px) {
    font-size: .7rem;
    margin: .3rem 1rem;
  }

  @media only screen and (max-width: 400px) {
    margin: .8rem 1rem;
  }
`

const ArticleWrapper = styled.div`
  display: flex;
`
const Cover = styled.div`
  width: 20%;
  margin: 1rem;

  @media only screen and (max-width: 1120px) {
    width: 30%;
  }

  @media only screen and (max-width: 500px) {
    width: 40%;
  }
`
const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 1120px) { 
    width: 70%;
  }  

  @media only screen and (max-width: 500px) {
    width: 60%;
  }
`
const Category = styled.h2`
  text-transform: uppercase;
  font-family: 'Josefin Sans', 'sans-serif';
  font-weight: 700;
  font-size: 1.5rem;
  color: black;

  @media only screen and (max-width: 800px) {
    font-size: 1.3rem;
  }

  @media only screen and (max-width: 680px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: .8rem;
  }

  @media only screen and (max-width: 300px) {
    font-size: .7rem;
  }
`
const Title = styled.h1`
  font-family: 'Oswald', 'sans-serif';
  color: black;
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: 0.5px;

  @media only screen and (max-width: 1120px) {
    font-size: 35px;
    line-height: 45px;
  }

  @media only screen and (max-width: 800px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media only screen and (max-width: 680px) {
    font-size: 25px;
    line-height: 35px;
  }

  @media only screen and (max-width: 680px) {
    font-size: 25px;
    line-height: 35px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 18px;
    line-height: 25px;
  }

  @media only screen and (max-width: 350px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const IndexWrapper = styled.main`
  
  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {
    padding: 15px;
  }
`

const PostWrapper = styled.div`
  margin: 1rem auto;
`

const Image = styled(Img)`
  border-radius: 5px;
`
export const query = graphql`
  query {
    allPublishedPostsByDate: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } },
      limit: 6
    ) {
      nodes {
        id
        excerpt(pruneLength: 100)
        frontmatter {
          title
          writtenBy
          category
          date(fromNow: true)
          cover {
            publicURL
            childImageSharp {
              sizes(maxWidth: 2000, maxHeight: 1000, traceSVG: { color: "#0f9449" }) {
                ...GatsbyImageSharpSizes_tracedSVG
              }
            }
          }
          coverName
        }

        fields {
          slug
        }
      }
    }
  }
`