import React from 'react'
import Slider from 'react-animated-slider';
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from 'styled-components'
import 'react-animated-slider/build/horizontal.css'
import './customSliderCss.css'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'

export default () => {

    const { siteUrl } = useSiteMetadata()

    const data = useStaticQuery(graphql`
        query {
            lastThreeCoversOfAllPublishedPostsByDate: 
              allMdx(
                sort: { fields: [frontmatter___date], order: DESC }
                filter: { 
                  frontmatter: { 
                    published: { eq: true }, 
                    inSlider: {eq: true}
                }}
                limit: 4
              ) {
                  nodes {
                      id
                      frontmatter {
                          title
                          cover {
                          childImageSharp {
                              sizes(maxWidth: 900, traceSVG: { color: "#0f9449" }) {
                                ...GatsbyImageSharpSizes_tracedSVG
                              }
                            }
                          }
                      }
              
                      fields {
                          slug
                      }
                  }
              }
        }
    `)

    return (
        <Slider
          autoplay={3000}
          infinite={true}
        >
          {data.lastThreeCoversOfAllPublishedPostsByDate.nodes.map((item) => (
              
              <Link
                  key={item.id}
                  style={{
                    background: `url('${siteUrl}${item.frontmatter.cover.childImageSharp.sizes.src}') no-repeat center center`,
                    backgroundSize: 'cover',
                    height: '100%',
                    width: '100%',
                    textDecoration: 'none'
                  }}
                  to={item.fields.slug}
              >
                <Title>
                  <span>
                    {item.frontmatter.title}
                  </span>
                  
                </Title>
                
                  
              </Link>
          ))}
        </Slider>
    )
}

const Title = styled.div`
  line-height: 2; 
  padding: 2px 0; 
  width: 60%;
  margin: 40px auto;
  color: black;

  span {
    
    font-family: 'Oswald', 'sans-serif';
    font-weight: 300;
    font-size: 2em;
    background: ${props => props.theme.transparentGreen};  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, ${props => props.theme.transparentGreen}, r${props => props.theme.transparentWhite}, ${props => props.theme.transparentRed});  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, ${props => props.theme.transparentGreen}, ${props => props.theme.transparentWhite}, ${props => props.theme.transparentRed}); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    text-transform: uppercase;
    display: inline;
    padding: 0.35rem;
    
    /* Needs prefixing */
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }
`

